import * as React from "react";
// markup
const NotFoundPage = (props) => {
  return (
    <>
      <p>404</p>
    </>
  );
};

export default NotFoundPage;
